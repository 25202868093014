import { defineStore } from 'pinia'

export const useAuthStore = defineStore('useAuthStore', () => {
    const { status, data, signOut, refresh } = useAuth()
    const cleanupInProgress = ref(false)
    const cleanupPromise = ref<Promise<void> | null>(null)
    const refreshTries = ref(0)

    const authenticated = computed(() => status.value === 'authenticated')
    const account = computed(() => data.value ?? null)
    const hasScango = computed(() => account.value?.features.includes('scango') ?? false)

    const handleLogout = async () => {
        if (cleanupInProgress.value) {
            return cleanupPromise.value
        }

        cleanupInProgress.value = true
        cleanupPromise.value = (async () => {
            try {
                // Clear tokens first
                const authToken = useCookie('auth.token')
                const refreshToken = useCookie('auth.refresh-token')

                // Force immediate cookie removal
                authToken.value = null
                refreshToken.value = null

                // Sign out without redirect
                await signOut({ redirect: false })

                useBroadcast().broadcastLogout()

                // Force clear any remaining fetch caches
                await refreshNuxtData()
            }
            catch (error) {
                console.error('Error while logging out', error)
                // Even if there's an error, try to clean up
                await clearNuxtData()
                await refreshNuxtData()
            }
            finally {
                cleanupInProgress.value = false
                cleanupPromise.value = null
            }
        })()

        return cleanupPromise.value
    }

    const handleRefresh = async () => {
        console.info('AuthStore: Refreshing token')
        if (cleanupInProgress.value) {
            console.info('AuthStore: Cleanup in progress, skipping refresh')
            return
        }

        if (refreshTries.value > 3) {
            console.info('AuthStore: Too many refresh tries, logging out')
            await handleLogout()
            return
        }

        try {
            console.info('AuthStore: Executing refresh')
            await refresh()
        }
        catch (error) {
            console.info('AuthStore: Token refresh failed, logging out')
            await handleLogout()
        }
    }

    // const handleLogin = async (email: string, password: string, redirectUrl: string, closeCallback: () => void) => {
    //     error.value = null
    //     const credentials = {
    //         email,
    //         password,
    //     }

    //     try {
    //         const response = await signIn(credentials, {
    //             redirect: false,
    //             callbackUrl: redirectUrl,
    //         })

    //         if (response?.error) {
    //             error.value = { status: response.status ?? 500, message: response.error }
    //         } else {
    //             closeCallback()

    //             await navigateTo(redirectUrl)
    //         }
    //     } catch (err: any) {
    //         error.value = { status: err.status ?? 500, message: err.message ?? 'Unknown error' }
    //     }
    // }

    return {
        authenticated,
        account,
        handleLogout,
        handleRefresh,
        hasScango,
        cleanupInProgress: readonly(cleanupInProgress),
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
