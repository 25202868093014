import type { RefreshHandler } from '@sidebase/nuxt-auth'
import { useAuth } from '#imports'
import { useAuthStore } from '~/stores/useAuthStore'

class CustomRefreshHandler implements RefreshHandler {
    private refreshInterval: NodeJS.Timeout | null = null
    private focusListener: ((event: FocusEvent) => void) | null = null
    private isRefreshing = false
    private refreshPromise: Promise<void> | null = null
    private lastRefreshTime = 0
    private readonly MIN_REFRESH_INTERVAL = 1000 // Minimum 1 second between refreshes
    private lastFocusRefreshTime = 0
    private readonly FOCUS_REFRESH_INTERVAL = 20 * 60 * 1000 // 20 minutes

    // Add these new properties
    private readonly TOKEN_LIFETIME = 1800 // 30 minutes (sync with your nuxt.config.ts)
    private readonly REFRESH_BEFORE_EXPIRY = 0.75 // Refresh when 75% of token lifetime has passed

    // private authStore = useAuthStore()

    private calculateNextRefreshTime(): number {
        // Calculate when to refresh - at 75% of token lifetime
        return this.TOKEN_LIFETIME * this.REFRESH_BEFORE_EXPIRY * 1000 // Convert to milliseconds
    }

    private async doRefresh(retries = 3): Promise<void> {
        try {
            const authStore = useAuthStore()
            if (authStore.cleanupInProgress) {
                return
            }

            const refreshToken = useCookie('auth.refresh-token')
            if (!refreshToken.value) {
                await authStore.handleLogout()
                return
            }

            const { refresh } = useAuth()

            await refresh()
            this.lastRefreshTime = Date.now()
        }
        catch (error: any) {
            const authStore = useAuthStore()

            if (error?.response?.status === 401) {
                this.destroy()
                await authStore.handleLogout()
                return
            }
            else if (error?.response?.status === 400) {
                this.destroy()
                await authStore.handleLogout()
                return
            }

            if (retries > 0 && ![401, 400].includes(error?.response?.status)) {
                await new Promise(resolve => setTimeout(resolve, 1000))
                return this.doRefresh(retries - 1)
            }
            throw error
        }
    }

    async refreshToken() {
        const authStore = useAuthStore()

        // Skip refresh if not authenticated
        if (!authStore.authenticated) {
            return
        }

        // Prevent refresh spamming
        if (Date.now() - this.lastRefreshTime < this.MIN_REFRESH_INTERVAL) {
            return
        }

        // If already refreshing, return existing promise
        if (this.isRefreshing) {
            return this.refreshPromise
        }

        this.isRefreshing = true
        this.refreshPromise = this.doRefresh().finally(() => {
            this.isRefreshing = false
            this.refreshPromise = null
        })

        return this.refreshPromise
    }

    init(): void {
        // Only initialize if not already initialized
        if (this.refreshInterval || this.focusListener) {
            this.destroy()
        }

        const authStore = useAuthStore()

        if (authStore.authenticated) {
            const refreshTime = this.calculateNextRefreshTime()
            this.refreshInterval = setInterval(() => {
                if (!authStore.authenticated) {
                    this.destroy()
                    return
                }
                this.refreshToken()
            }, refreshTime)

            if (typeof window !== 'undefined') {
                this.focusListener = () => {
                    const timeSinceLastFocusRefresh = Date.now() - this.lastFocusRefreshTime
                    if (timeSinceLastFocusRefresh < this.FOCUS_REFRESH_INTERVAL) {
                        // Skip refresh if less than 20 minutes since last focus refresh
                        return
                    }

                    this.lastFocusRefreshTime = Date.now()
                    this.refreshToken()
                }
                window.addEventListener('focus', this.focusListener)
            }
        }
    }

    destroy(): void {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval)
            this.refreshInterval = null
        }

        if (this.focusListener && typeof window !== 'undefined') {
            window.removeEventListener('focus', this.focusListener)
            this.focusListener = null
        }

        this.isRefreshing = false
        this.refreshPromise = null
        this.lastRefreshTime = 0
        this.lastFocusRefreshTime = 0
    }
}

const handler = new CustomRefreshHandler()

// Export both the handler directly and wrap it in defineNuxtPlugin
export default handler

// Also export a plugin version
export const plugin = defineNuxtPlugin(() => {
    return {
        provide: {
            refreshHandler: handler,
        },
    }
})
