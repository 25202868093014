<template>
    <NuxtLoadingIndicator color="#0891b2" />
    <template v-if="authReady">
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </template>
</template>

<script setup lang="ts">
import { appDescription, appName } from '~/constants'

useHead({
    title: appName,
    meta: [{ name: appName, content: appDescription }],
})

useSeoMeta({
    title: appName,
    ogTitle: appName,
    ogImage: 'https://nieuw.demegro.nl/favicon.png',
    ogUrl: 'https://nieuw.demegro.nl',
    ogDescription: appDescription,
})

const { status: authStatus, getSession, lastRefreshedAt } = useAuth()
const authReady = ref(false)

useBroadcast()

onMounted(async () => {
    try {
        // Try refresh first if access token is expired
        if (lastRefreshedAt.value instanceof Date && (Date.now() - lastRefreshedAt.value.getTime() > 1000 * 60 * 29)) { // 29 minutes
            console.info('App: Token expired, refreshing')
            try {
                console.info('App: Refreshing token')
                await useAuthStore().handleRefresh()
            }
            catch (error) {
                console.info('App: Token refresh failed, logging out')
                await useAuthStore().handleLogout()
            }
        }
        console.info('App: Getting session')
        // Then get session
        await getSession()
    }
    finally {
        authReady.value = true
    }
})

const authenticated = useState('authenticated', () => authStatus.value === 'authenticated')

watch(authStatus, (newStatus) => {
    authenticated.value = newStatus === 'authenticated'
})
</script>

<style>
html,
body,
#__nuxt {
    height: 100vh;
    margin: 0;
    padding: 0;
}

html.dark {
    background: #222;
    color: white;
}
</style>
