import { config, library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far as farPro } from '@fortawesome/pro-regular-svg-icons'
import { fas as fasPro } from '@fortawesome/pro-solid-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { faD as fadSharp } from '@fortawesome/sharp-duotone-solid-svg-icons'
import { faL as falSharp } from '@fortawesome/sharp-light-svg-icons'
import { faR as farSharp } from '@fortawesome/sharp-regular-svg-icons'
import { faS as fasSharp } from '@fortawesome/sharp-solid-svg-icons'
import { faT as fatSharp } from '@fortawesome/sharp-thin-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

config.autoAddCss = false

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(fas, far, fab, fad, fal, farPro, fasPro, fat, fadSharp, falSharp, farSharp, fasSharp, fatSharp)

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon)
})
